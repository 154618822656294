// export const URL = "http://192.168.15.11:8000/";
// export const URL_SOCKET = "http://192.168.15.11:5001";
export const DEBUG = false;


// export const URL = "http://localhost:8000/";
// export const URL_SOCKET = "http://localhost:5001";
// export const THIS_URL = "http://localhost:3001";
// export const URL = "http://ec2-54-233-173-205.sa-east-1.compute.amazonaws.com/";
export const URL = "https://api.ontest.com.br/";
export const URL_SOCKET = "https://node.ontest.com.br";
export const THIS_URL = "https://candidato.ontest.com.br";

export const TURN_1 = "turn1.ontest.com.br";
export const TURN_2 = "turn2.ontest.com.br";
export const TURN_3 = "turn3.ontest.com.br";
export const TURN_4 = "turn4.ontest.com.br";

// export const URL = "https://onteste.enterscience.com.br/";
// export const URL_SOCKET = "https://node.enterscience.com.br";

// export const TURN_1 = "turn1.enterscience.com.br";
// export const TURN_2 = "turn2.enterscience.com.br";
// export const TURN_3 = "turn3.enterscience.com.br";
// export const TURN_4 = "turn4.enterscience.com.br";



// export const URL = "https://onteste.itec.edu.br/";
// export const URL_SOCKET = "https://node.itec.edu.br";

// export const TURN_1 = "turn1.itec.edu.br";
// export const TURN_2 = "turn2.itec.edu.br";
// export const TURN_3 = "turn3.itec.edu.br";
// export const TURN_4 = "turn4.itec.edu.br";
